import React,{ useState }  from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import headerImage from "../images/header.png"
import MockupContent from "./image"
import mockupFrame from "../images/mockup-frame.png"
import StripeCheckout from 'react-stripe-checkout';

const onToken = (token) => {
  fetch('/save-stripe-token', {
    method: 'POST',
    body: JSON.stringify(token),
  }).then(response => {
    response.json().then(data => {
      alert(`We are in business, ${data.email}`);
    });
  });
}
const Header = ({ siteTitle }) => {
  const [amount, setAmount] = useState(0);

  return(  

  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "9rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>Le Gateau Quick Pay</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      Make a quick payment to Le Gateau Cakes <br /><i>- Powered by Instadukan.com</i>
    </p>
    Amount (INR)
    <input style={{marginBottom:"10px"}} type="text" onChange={(e)=>{setAmount(e.target.value);console.log(amount)}}></input>
    <br />
    <br />
    <StripeCheckout
        token={onToken}
        stripeKey="pk_live_ChtX4PtTUMH36B9MxuppbJVb009VlutfdK"
        name="Le Gateau cakes"
        description="Quick pay for Le Gateau Cakes" 
        amount={amount*100} // cents
        currency="INR"
      />
  
  </div>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
